// "use client";
// import { useEffect, useRef, useState } from "react";
// import { Bilbo_Swash_Caps } from "next/font/google";
// import hamburger from "@/public/assets/hamburger.avif";
// import cross from "@/public/assets/cross.avif";

// import Link from "next/link";
// import Image from "next/image";

// const bilboSwashCaps = Bilbo_Swash_Caps({
//   display: "swap",
//   subsets: ["latin"],
//   weight: ["400"],
//   adjustFontFallback: {
//     sizeAdjust: "96%", // Match fallback font metrics to Poppins
//   },
//   preload: true,
// });

// const NavBar = () => {
//   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
//   const [isMobileRecipesMenuOpen, setIsMobileRecipesMenuOpen] = useState(false);
//   const divEl = useRef(null);

//   useEffect(() => {
//     const handler = (event) => {
//       if (!divEl.current) {
//         return;
//       }
//       if (!divEl.current.contains(event.target)) {
//         setIsMobileMenuOpen(false);
//         setIsMobileRecipesMenuOpen(false);
//       }
//     };
//     document.addEventListener("click", handler, true);
//     return () => {
//       document.removeEventListener("click", handler);
//     };
//   }, []);

//   const toggleMobileMenu = () => {
//     setIsMobileMenuOpen(!isMobileMenuOpen);
//   };

//   const toggleMobileRecipesMenu = () => {
//     setIsMobileRecipesMenuOpen(!isMobileRecipesMenuOpen);
//   };

//   const closeMenus = () => {
//     setIsMobileMenuOpen(false);
//     setIsMobileRecipesMenuOpen(false);
//   };

//   return (
//     <nav className="relative z-50" ref={divEl}>
//       <div className="md:flex inline-block justify-between items-center md:h-15 mx-auto bg-transparent md:px-20 w-full md:max-w-[2440px]">
//         <div className="flex items-center justify-between py-5 md:py-0 px-6 md:px-0">
//           <Link
//             prefetch={true}
//             scroll={true}
//             className={`text-black text-2xl md:text-4xl ${bilboSwashCaps.className}`}
//             aria-label="Visit the homepage for My Creative Recipes"
//             href="/"
//           >
//             My Creative Recipes
//           </Link>

//           <div
//             className="block md:hidden text-black text-2xl"
//             onClick={toggleMobileMenu}
//           >
//             {isMobileMenuOpen ? (
//               <Image
//                 className="duration-500 rotate-180"
//                 src={cross}
//                 alt="cross"
//                 height={20}
//                 width={20}
//                 role="presentation"
//                 aria-hidden="true"
//                 unoptimized
//               />
//             ) : (
//               <Image
//                 className="duration-500 -rotate-180"
//                 src={hamburger}
//                 alt="hamburger"
//                 height={20}
//                 width={20}
//                 aria-label="hamburger"
//                 unoptimized
//               />
//             )}
//           </div>
//         </div>

//         {/* Desktop Menu */}
//         <ul className="hidden md:flex">
//           <li className="font-normal text-black hover:text-primary p-2">
//             <Link prefetch={true} scroll={true} href="/" onClick={closeMenus}>
//               Home
//             </Link>
//           </li>

//           <li className="font-normal text-black hover:text-primary p-2">
//             <Link
//               prefetch={true}
//               scroll={true}
//               href="/categories"
//               onClick={closeMenus}
//             >
//               Categories
//             </Link>
//           </li>
//           <li className="font-normal text-black hover:text-primary p-2">
//             <Link
//               prefetch={true}
//               scroll={true}
//               href="/about"
//               onClick={closeMenus}
//             >
//               About Me
//             </Link>
//           </li>
//           <li className="font-normal text-black hover:text-primary p-2">
//             <Link
//               prefetch={true}
//               scroll={true}
//               href="/contact-me"
//               onClick={closeMenus}
//             >
//               Contact Me
//             </Link>
//           </li>
//         </ul>

//         {/* Mobile Menu */}
//         <ul
//           className={`${
//             isMobileMenuOpen ? "block" : "hidden"
//           } sm:hidden bg-lite w-full text-start rounded px-5 divide-y divide-white`}
//         >
//           <li className="font-normal text-black hover:text-primary p-2">
//             <Link prefetch={true} scroll={true} href="/" onClick={closeMenus}>
//               Home
//             </Link>
//           </li>

//           <li className="font-normal text-black hover:text-primary p-2">
//             <Link
//               prefetch={true}
//               scroll={true}
//               href="/categories"
//               onClick={closeMenus}
//             >
//               Categories
//             </Link>
//           </li>
//           <li className="font-normal text-black hover:text-primary p-2">
//             <Link
//               prefetch={true}
//               scroll={true}
//               href="/about"
//               onClick={closeMenus}
//             >
//               About Me
//             </Link>
//           </li>
//           <li className="font-normal text-black hover:text-primary p-2">
//             <Link
//               prefetch={true}
//               scroll={true}
//               href="/contact-me"
//               onClick={closeMenus}
//             >
//               Contact Me
//             </Link>
//           </li>
//         </ul>
//       </div>
//     </nav>
//   );
// };

// export default NavBar;

// "use client";
// import { useEffect, useRef, useState } from "react";
// import { Bilbo_Swash_Caps } from "next/font/google";
// import hamburger from "@/public/assets/hamburger.avif";
// import cross from "@/public/assets/cross.avif";
// import Link from "next/link";
// import Image from "next/image";

// const bilboSwashCaps = Bilbo_Swash_Caps({
//   display: "swap",
//   subsets: ["latin"],
//   weight: ["400"],
//   adjustFontFallback: {
//     sizeAdjust: "96%",
//   },
//   preload: true,
// });

// export default function NavBar() {
//   // Initialize states as undefined to avoid SSR mismatch
//   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(undefined);
//   const [isMobileRecipesMenuOpen, setIsMobileRecipesMenuOpen] =
//     useState(undefined);
//   const navRef = useRef(null);

//   // Initialize state on client-side only
//   useEffect(() => {
//     setIsMobileMenuOpen(false);
//     setIsMobileRecipesMenuOpen(false);
//   }, []);

//   // Close menu when clicking outside
//   useEffect(() => {
//     if (isMobileMenuOpen === undefined || isMobileRecipesMenuOpen === undefined)
//       return;

//     const handleClickOutside = (event) => {
//       if (navRef.current && !navRef.current.contains(event.target)) {
//         setIsMobileMenuOpen(false);
//         setIsMobileRecipesMenuOpen(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => document.removeEventListener("mousedown", handleClickOutside);
//   }, [isMobileMenuOpen, isMobileRecipesMenuOpen]);

//   // Navigation items data
//   const navItems = [
//     { href: "/", label: "Home" },
//     { href: "/categories", label: "Categories" },
//     { href: "/about", label: "About Me" },
//     { href: "/contact-me", label: "Contact Me" },
//   ];

//   // Don't render interactive elements on server to avoid mismatch
//   if (isMobileMenuOpen === undefined || isMobileRecipesMenuOpen === undefined) {
//     return (
//       <nav className="relative z-50" ref={navRef}>
//         <div className="md:flex inline-block justify-between items-center md:h-15 mx-auto bg-transparent md:px-20 w-full md:max-w-[2440px]">
//           <div className="flex items-center justify-between py-5 md:py-0 px-6 md:px-0">
//             <Link
//               prefetch={true}
//               scroll={true}
//               className={`text-black text-2xl md:text-4xl ${bilboSwashCaps.className}`}
//               aria-label="Visit the homepage for My Creative Recipes"
//               href="/"
//             >
//               My Creative Recipes
//             </Link>
//           </div>
//         </div>
//       </nav>
//     );
//   }

//   return (
//     <nav className="relative z-50" ref={navRef}>
//       <div className="md:flex inline-block justify-between items-center md:h-15 mx-auto bg-transparent md:px-20 w-full md:max-w-[2440px]">
//         <div className="flex items-center justify-between py-5 md:py-0 px-6 md:px-0">
//           <Link
//             prefetch={true}
//             scroll={true}
//             className={`text-black text-2xl md:text-4xl ${bilboSwashCaps.className}`}
//             aria-label="Visit the homepage for My Creative Recipes"
//             href="/"
//             onClick={() => {
//               setIsMobileMenuOpen(false);
//               setIsMobileRecipesMenuOpen(false);
//             }}
//           >
//             My Creative Recipes
//           </Link>

//           <button
//             type="button"
//             className="md:hidden p-2 rounded-md text-gray-700 hover:text-primary focus:outline-none"
//             onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
//             aria-label="Toggle menu"
//             aria-expanded={isMobileMenuOpen}
//           >
//             <Image
//               src={isMobileMenuOpen ? cross : hamburger}
//               alt={isMobileMenuOpen ? "Close menu" : "Open menu"}
//               width={20}
//               height={20}
//               className="h-5 w-5 duration-500"
//               style={{
//                 transform: isMobileMenuOpen
//                   ? "rotate(180deg)"
//                   : "rotate(-180deg)",
//               }}
//               unoptimized
//             />
//           </button>
//         </div>

//         {/* Desktop Navigation */}
//         <div className="hidden md:flex">
//           {navItems.map((item) => (
//             <Link
//               key={item.href}
//               prefetch={true}
//               scroll={true}
//               href={item.href}
//               className="font-normal text-black hover:text-primary p-2"
//               onClick={() => {
//                 setIsMobileMenuOpen(false);
//                 setIsMobileRecipesMenuOpen(false);
//               }}
//             >
//               {item.label}
//             </Link>
//           ))}
//         </div>

//         {/* Mobile Navigation */}
//         {isMobileMenuOpen && (
//           <div className="md:hidden absolute top-16 left-0 right-0 bg-white shadow-lg">
//             <div className="px-2 pt-2 pb-3 space-y-1">
//               {navItems.map((item) => (
//                 <Link
//                   key={item.href}
//                   prefetch={true}
//                   scroll={true}
//                   href={item.href}
//                   className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-primary hover:bg-gray-50"
//                   onClick={() => {
//                     setIsMobileMenuOpen(false);
//                     setIsMobileRecipesMenuOpen(false);
//                   }}
//                 >
//                   {item.label}
//                 </Link>
//               ))}
//             </div>
//           </div>
//         )}
//       </div>
//     </nav>
//   );
// }

"use client";
import { useEffect, useRef, useState } from "react";
import { Bilbo_Swash_Caps } from "next/font/google";
import { AiOutlineAlignRight } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";

// import hamburger from "@/public/assets/hamburger.avif";
import cross from "@/public/assets/cross.avif";
import Link from "next/link";
import Image from "next/image";

const bilboSwashCaps = Bilbo_Swash_Caps({
  display: "swap",
  subsets: ["latin"],
  weight: ["400"],
  adjustFontFallback: {
    sizeAdjust: "96%",
  },
  preload: true,
});

export default function NavBar() {
  // Initialize states as undefined to avoid SSR mismatch
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(undefined);
  const [isMobileRecipesMenuOpen, setIsMobileRecipesMenuOpen] =
    useState(undefined);
  const navRef = useRef(null);

  // Initialize state on client-side only
  useEffect(() => {
    setIsMobileMenuOpen(false);
    setIsMobileRecipesMenuOpen(false);
  }, []);

  // Close menu when clicking outside
  useEffect(() => {
    if (isMobileMenuOpen === undefined || isMobileRecipesMenuOpen === undefined)
      return;

    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsMobileMenuOpen(false);
        setIsMobileRecipesMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isMobileMenuOpen, isMobileRecipesMenuOpen]);

  // Navigation items data
  const navItems = [
    { href: "/", label: "Home" },
    { href: "/categories", label: "Categories" },
    { href: "/about", label: "About Me" },
    { href: "/contact-me", label: "Contact Me" },
  ];

  // Don't render interactive elements on server to avoid mismatch
  if (isMobileMenuOpen === undefined || isMobileRecipesMenuOpen === undefined) {
    return (
      <nav className="relative z-50" ref={navRef}>
        <div className="md:flex inline-block justify-between items-center md:h-15 mx-auto bg-transparent md:px-20 w-full md:max-w-[2440px]">
          <div className="flex items-center justify-between py-5 md:py-0 px-6 md:px-0">
            <Link
              prefetch={true}
              scroll={true}
              className="text-black text-2xl md:text-4xl" // Removed dynamic font class during SSR
              aria-label="Visit the homepage for My Creative Recipes"
              href="/"
            >
              My Creative Recipes
            </Link>
          </div>
        </div>
      </nav>
    );
  }

  return (
    <nav className="relative z-50" ref={navRef}>
      <div className="md:flex inline-block justify-between items-center md:h-15 mx-auto bg-transparent md:px-20 w-full md:max-w-[2440px]">
        <div className="flex items-center justify-between py-5 md:py-0 px-6 md:px-0">
          <Link
            prefetch={true}
            scroll={true}
            className={`text-black text-2xl md:text-4xl ${bilboSwashCaps.className}`}
            aria-label="Visit the homepage for My Creative Recipes"
            href="/"
            onClick={() => {
              setIsMobileMenuOpen(false);
              setIsMobileRecipesMenuOpen(false);
            }}
          >
            My Creative Recipes
          </Link>

          <button
            type="button"
            className="md:hidden p-2 rounded-md text-gray-700 hover:text-primary focus:outline-none"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            aria-label="Toggle menu"
            aria-expanded={isMobileMenuOpen}
          >
            {/* <Image
              src={isMobileMenuOpen ? cross : <AiOutlineAlignRight />}
              alt={isMobileMenuOpen ? "Close menu" : "Open menu"}
              width={20}
              height={20}
              className="h-5 w-5 duration-500"
              style={{
                transform: isMobileMenuOpen
                  ? "rotate(180deg)"
                  : "rotate(-180deg)",
              }}
              unoptimized
            /> */}
            {isMobileMenuOpen ? <RxCross2 /> : <AiOutlineAlignRight />}
          </button>
        </div>

        {/* Desktop Navigation */}
        <div className="hidden md:flex">
          {navItems.map((item) => (
            <Link
              key={item.href}
              prefetch={true}
              scroll={true}
              href={item.href}
              className="font-normal text-black hover:text-primary p-2"
              onClick={() => {
                setIsMobileMenuOpen(false);
                setIsMobileRecipesMenuOpen(false);
              }}
            >
              {item.label}
            </Link>
          ))}
        </div>

        {/* Mobile Navigation */}
        {isMobileMenuOpen && (
          <div className="md:hidden absolute top-16 left-0 right-0 bg-white shadow-lg">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navItems.map((item) => (
                <Link
                  key={item.href}
                  prefetch={true}
                  scroll={true}
                  href={item.href}
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-primary hover:bg-gray-50"
                  onClick={() => {
                    setIsMobileMenuOpen(false);
                    setIsMobileRecipesMenuOpen(false);
                  }}
                >
                  {item.label}
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
}
